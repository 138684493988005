<script lang="ts" setup>
import type { StyleValue } from 'vue'

const { content, title = '', type = 'Accordion', maxHeight = 0.5 } = defineProps<{
  content: string
  title?: string
  type?: 'Accordion'
  maxHeight?: number
}>()

const slots = defineSlots()

const activeNames = ref()

const show = ref(false)

const contentStyle = computed<StyleValue>(() => {
  return {
    maxHeight: maxHeight ? `calc(100vh * ${maxHeight})` : undefined,
    overflowY: 'auto',
  }
})

watch(() => activeNames.value, (val) => {
  if (val) {
    show.value = true
  }
})
</script>

<template>
  <div v-if="content?.length">
    <van-collapse v-if="type === 'Accordion'" v-model="activeNames" accordion>
      <van-collapse-item name="1">
        <template #title>
          <div v-if="slots.title">
            {{ slots.title() }}
          </div>
          <div v-else class="flex flex-row items-center gap-x-1">
            <van-icon name="info-o" size="5px" />
            <div>{{ title }}</div>
          </div>
        </template>
        <div :style="contentStyle">
          <div class="prose prose-truegray" v-html="content" />
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<style scoped lang="scss">
:deep() {
  .van-icon-arrow:before {
    content: '';
  }
}
:deep(.van-cell) {
  padding: 0;
  height: 10px;
}
</style>
